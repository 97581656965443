import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  connect() {
    new SlimSelect({ select: this.element });

    document.addEventListener("turbo:load", () => {
      document.querySelectorAll(".from-version").forEach((frame) => {
        frame.src = `/notifications/${frame.dataset.notificationId}/versions/${frame.dataset.id}?from=true`;
      });
      document.querySelectorAll(".to-version").forEach((frame) => {
        frame.src = `/notifications/${frame.dataset.notificationId}/versions/${frame.dataset.id}?to=true`;
      });
      const frame = document.querySelector("#new_parts");
      frame.src = `/notifications/${frame.dataset.id}/new_parts`;
    });
  }

  updateRank(event) {
    const rank = event.target.value;
    const anchor = document.getElementById('rank_updater');
    anchor.href = `/notifications/rank?rank=${rank}`;
    anchor.click();
  }

  addUpgradingVersion(event) {
    const parent = event.target.closest('#upgrading_versions');
    const [fromId, fromName] = parent.querySelector('#from_version').value.split(/:(.+)/);
    const [toId, toName] = parent.querySelector('#to_version').value.split(/:(.+)/);

    const cnt = document.querySelectorAll("#from_version_names div").length;
    document.querySelector("#from_version_names")
      .insertAdjacentHTML('beforeend', `
        <div class='flex'>
          <a href='javascript:void(0);' class='text-red-500 block mb-1' data-id="${cnt}" data-action='click->notification#removeUpgradingVersion'>
            ${fromName || '指定なし'}
            <input type="hidden" value="${fromId}" name="upgrade_notification[versions_attributes][${cnt}][from_version_id]">
            <input type="hidden" value="${toId}" name="upgrade_notification[versions_attributes][${cnt}][to_version_id]">
          </a>
        </div>
      `);
    document.querySelector("#to_version_names")
      .insertAdjacentHTML('beforeend', `<div class='mb-1' id="to_${cnt}">${toName || '指定なし'}</div>`);
    const fr = new SlimSelect({ select: '#from_version' });
    fr.setSelected('');
    const to = new SlimSelect({ select: '#to_version' });
    to.setSelected('');
  }

  removeUpgradingVersion(event) {
    const dataId = event.target.dataset.id;
    const parent = event.target.closest('div');
    parent.remove();
    document.getElementById(`to_${dataId}`).remove();
  }

  checkDelete(event) {
    if (event.target.checked) {
      event.target.closest('.version').classList.add('text-gray-300');
      event.target.nextSibling.classList.remove('hidden');
    } else {
      event.target.closest('.version').classList.remove('text-gray-300');
      event.target.nextSibling.classList.add('hidden');
    }
  }
}